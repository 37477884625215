import { useEffect, useState } from "react"
import { Form, Input, Row, Col, Checkbox, Select, Tag } from 'antd'

export default function TableInput(props) {
  const [form] = Form.useForm();
  const item = props.item
  const pageActive = props?.pageActive
  const dataAllPage = props?.dataAllPage
  const [thuaDat, setThuaDat] = useState([])
  const [thayDoi, setThayDoi] = useState([])
  const [gachNgangThua, setGachNgangThua] = useState({})
  const [gachNgangThongTin, setGachNgangThongTin] = useState({})

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({
      age1: item?.age1 ? item?.age1 : '',
      age2: item?.age2 ? item?.age2 : '',
      cmnd1: item?.cmnd1 ? item?.cmnd1 : '',
      cmnd2: item?.cmnd2 ? item?.cmnd2 : '',
      fullname1: item?.fullname1 ? item?.fullname1 : '',
      fullname2: item?.fullname2 ? item?.fullname2 : '',
      address: item?.address ? item?.address : '',
      note: item?.note ? item?.note : '',
      page_sheet: item?.page_sheet ? item?.page_sheet : '',
      thuadat: item?.thuadat ? item?.thuadat : [],
      thaydoi: item?.thaydoi ? item?.thaydoi : [],
    })

    let listGachNgangThua = {}
    if (item?.thuadat) {
      item?.thuadat.map((item, key) => {
        if (item?.checklinethua === 'throght-thua') {
          listGachNgangThua[key] = item?.checklinethua
        }
      })
    }

    setGachNgangThua({ ...listGachNgangThua })

    setThuaDat(item?.thuadat ? item?.thuadat : [])
    setThayDoi(item?.thaydoi ? item?.thaydoi : [])
  }, [pageActive])

  const handleClearData = () => {
    form.resetFields()
  }

  const handleAddThuaDat = () => {
    thuaDat.push(thuaDat.length)
    setThuaDat([...thuaDat])
  }

  const handleAddThongtinthem = () => {
    thayDoi.push(thayDoi.length)
    setThayDoi([...thayDoi])
  }

  const onFinish = async (values) => {
    props?.UpdateData(values)
  };

  const handleDeleteTTThua = () => {
    thuaDat.shift();
    setThuaDat([...thuaDat])
  }

  const handleDeleteTTThem = () => {
    thayDoi.shift();
    setThayDoi([...thayDoi])
  }

  function convertToSlug(str) {
    // str = str.toLowerCase();
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    str = str.replace(/[đĐ]/g, 'd');
    // str = str.replace(/([^0-9a-z-\s])/g, '');
    str = str.replace(/(\s+)/g, '_');
    str = str.replace(/-+/g, '_');
    // str = str.replace(/^-+|-+$/g, '');
    return str;
  }

  const handAddValue = (value, keyValue, nameColumn, textGoiY) => {
    value[keyValue] = { [nameColumn]: textGoiY }
    console.log(value, 'value')
    form.setFieldsValue({ thuadat: value })
    setThuaDat(value)
  }

  const handleGachNgangThua = (value, key) => {
    gachNgangThua[key] = value
    setGachNgangThua({ ...gachNgangThua })
  }

  const handleGachNgangThongtin = (value, key) => {
    gachNgangThongTin[key] = value
    setGachNgangThongTin({ ...gachNgangThongTin })
  }

  return (
    <>
      <div className="doifyprdhs" style={{ display: 'block' }}>
        <Form
          form={form}
          onFinish={onFinish}>
          <div className="uzjbfcukks">
            <div className="yvilhnnvzw">
              <div>
                <button className="vowvrqnxli" onClick={() => handleAddThuaDat()}>Thêm thông tin thửa</button>
                <button className="vowvrqnxli" onClick={() => handleDeleteTTThua()}>Xóa thông tin thửa</button>
              </div>
              <div>
                <button className="vowvrqnxli" onClick={() => handleAddThongtinthem()}>Thông tin thêm</button>
                <button className="vowvrqnxli" onClick={() => handleDeleteTTThem()}>Xóa Thông tin thêm</button>
              </div>
              <button type="submit" className="vowvrqnxli">Lưu thông tin</button>
              <button className="vowvrqnxli" onClick={() => handleClearData()}>Clear thông tin</button>
            </div>
            <div className="zhtewhxuoh">
              Trang
              <Form.Item name="page_sheet">
                <Input style={{ width: 200 }} placeholder="Trang" />
              </Form.Item>
            </div>
          </div>
          <div>Bấm lưu thông tin trước khi chuyển trang hoặc xuất dữ liệu</div>
          <div>
            <div>Ghi chú:</div>
            <Form.Item name="note">
              <Input style={{ width: 100 }} placeholder="Ghi chú" />
            </Form.Item>
          </div>
          <table className="nlhrorilkm" border={1}>
            <tbody>
              <tr>
                <td colSpan={10} style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18, border: '1px solid #000000' }}>I-NGƯỜI SỬ DỤNG ĐẤT</td>
              </tr>
              <tr>
                <td colSpan={10}>
                  <Row>
                    <Col>
                      <div>Hộ ông:</div>
                      <Form.Item name="fullname1">
                        <Input width={100} placeholder="Họ tên" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <div>Sinh năm:</div>
                      <Form.Item name="age1">
                        <Input width={100} placeholder="Năm sinh" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <div>CMND:</div>
                      <Form.Item name="cmnd1">
                        <Input width={100} placeholder="Chứng minh thư" />
                      </Form.Item>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td colSpan={10}>
                  <Row>
                    <Col>
                      <div>Vợ là bà:</div>
                      <Form.Item name="fullname2">
                        <Input width={100} placeholder="Họ tên" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <div>Sinh năm:</div>
                      <Form.Item name="age2">
                        <Input width={100} placeholder="Năm sinh" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <div>CMND:</div>
                      <Form.Item name="cmnd2">
                        <Input width={100} placeholder="Chứng minh thư" />
                      </Form.Item>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td colSpan={10}>
                  <div>Địa chỉ:</div>
                  <Form.Item name="address" style={{ width: '100%' }}>
                    <Input placeholder="Địa chỉ" />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td colSpan={10} style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>II- THỬA ĐẤT</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }} span={2} rowSpan={2}>Ngày tháng <br /> năm vào</td>
                <td style={{ textAlign: 'center' }} rowSpan={2}>Số thứ tự <br /> thửa đất</td>
                <td style={{ textAlign: 'center' }} rowSpan={2}>Số thứ tự<br /> tờ bản đồ</td>
                <td style={{ textAlign: 'center' }} rowSpan={1} colSpan={2}>Diện tích sử dụng (m2)</td>
                <td style={{ textAlign: 'center' }} rowSpan={2}>Mục đích sử dụng</td>
                <td style={{ textAlign: 'center' }} rowSpan={2}>Thời hạn sử dụng</td>
                <td style={{ textAlign: 'center' }} rowSpan={2}>Nguồn gốc sử dụng</td>
                <td style={{ textAlign: 'center' }} rowSpan={2}>Số phát hành GCN QSDĐ</td>
                <td style={{ textAlign: 'center' }} rowSpan={2}>Số vào sổ cấp GCN QSDĐ</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>riêng</td>
                <td style={{ textAlign: 'center' }}>chung</td>
              </tr>
              {
                thuaDat && thuaDat.length > 0 ? thuaDat.map((tt1, keytt1) => {
                  let gachNgangValue = false
                  if (gachNgangThua[keytt1] === 'throght-thua') {
                    gachNgangValue = true
                  }

                  return (
                    <tr key={keytt1}>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `ngaythangnamvao`]} >
                          <Input width={100} placeholder="Ngày tháng năm vào" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `sothututhuadat`]} >
                          <Input width={100} placeholder="Số thứ tự thửa đất" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `sothutubando`]} >
                          <Input width={100} placeholder="Số thứ tự tờ bản đồ" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `rieng`]} >
                          <Input width={100} placeholder="Riêng" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `chung`]} >
                          <Input width={100} placeholder="Chung" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `mucdichsudung`]} >
                          <Input width={100} placeholder="Mục đích sử dụng" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `thoihansudung`]} >
                          <Input width={100} placeholder="Thời hạn sử dụng" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `nguongocsudung`]} >
                          <Input width={100} placeholder="Nguồn gốc sử dụng" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `sophathanhgcn`]} >
                          <Input width={100} placeholder="Số phát hành GCN QSDĐ" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thuadat', keytt1, `sovaosocapgcnqsdd`]} >
                          <Input width={100} placeholder="Số vào sổ cấp GCN QSDĐ" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td style={{ padding: '0 15px' }}>
                        <Form.Item name={['thuadat', keytt1, `checklinethua`]}>
                          <Select style={{ width: 150 }} onChange={(event) => handleGachNgangThua(event, keytt1)}>
                            <Select.Option value="throght-not-thua">Không gạch Ngang</Select.Option>
                            <Select.Option value="throght-thua">Gạch ngang</Select.Option>
                          </Select>
                        </Form.Item>
                      </td>
                    </tr>
                  )
                }) : null
              }
              <tr>
                <td colSpan={10} style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>III - NHỮNG THAY ĐỔI TRONG QUÁ TRÌNH SỬ DỤNG ĐẤT VÀ GHI CHÚ</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>Số thứ tự <br /> thửa đất</td>
                <td style={{ textAlign: 'center' }}>Ngày tháng <br /> năm</td>
                <td style={{ textAlign: 'center' }} colSpan={8}>Nội dung ghi chú hoặc biến động và căn cứ pháp lý</td>
              </tr>
              {
                thayDoi && thayDoi.length > 0 ? thayDoi.map((ttt, keyttt) => {
                  let gachNgangValue = false
                  if (gachNgangThongTin[keyttt] === 'throght-line') {
                    gachNgangValue = true
                  }
                  return (
                    <tr key={keyttt}>
                      <td>
                        <Form.Item name={['thaydoi', keyttt, `sothututhuadat`]} >
                          <Input width={100} placeholder="Số thứ tự thửa đất" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item name={['thaydoi', keyttt, `ngaythangnam`]} >
                          <Input width={100} placeholder="Ngày tháng năm" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td colSpan={8}>
                        <Form.Item name={['thaydoi', keyttt, `noidung`]} >
                          <Input.TextArea rows={5} width={100} placeholder="Nội dung ghi chú hoặc biến động và căn cứ pháp lý" style={{ textDecoration: gachNgangValue ? 'line-through' : 'none' }} />
                        </Form.Item>
                      </td>
                      <td style={{ padding: '0 15px' }}>
                        <Form.Item name={['thaydoi', keyttt, `checkline`]}>
                          <Select style={{ width: 150 }} onChange={(event) => handleGachNgangThongtin(event, keyttt)}>
                            <Select.Option value="throght-not-line">Không gạch Ngang</Select.Option>
                            <Select.Option value="throght-line">Gạch ngang</Select.Option>
                          </Select>
                        </Form.Item>
                      </td>
                    </tr>
                  )
                }) : null
              }
            </tbody>
          </table>
        </Form>
      </div>
    </>
  )
}