import {axiosGET, axiosPOST} from "../Helper/TypeAxios";
import {urlParseParams} from "../Helper/helpFunction";

export default class Permission {
    fetchRouter() {
        return axiosPOST(`/permission/fetchRouter`, {})
    }

    getAll() {
        return axiosGET(`/permission/getAll`, {})
    }
    getAllGroup() {
        return axiosGET(`/permission/getAllGroup`, {})
    }

    createData(data) {
        return axiosPOST(`/permission/create`, data)
    }

    updateData(id, data) {
        return axiosPOST(`/permission/updateData/${id}`, data)
    }

    createGroup(data) {
        return axiosPOST(`/permission/createGroup`, data)
    }

    detailData(id) {
        return axiosGET(`/permission/detail/${id}`, {})
    }

    list(data) {
        const stringUrl = urlParseParams(data);
        return axiosGET(`/permission/list?${stringUrl}`, {})
    }
}
