import { Button, Col, DatePicker, Form, Input, Card, Row, Select, Spin } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../service/API";

export default function Search(props) {
    const [form] = Form.useForm();
    const [params, setParams] = useState({});
    const village = props?.village
    const wards = props?.wards

    const onFinish = (values) => {
        props?.data(values)
    }

    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <Card title="Tìm kiếm" >
                    <Form form={form} onFinish={onFinish}>
                        <Row gutter={16} style={{ marginBottom: 12 }}>
                            <Col span={3}>
                                <div>Thôn</div>
                                <Form.Item label="" name="village_id" >
                                    <Select style={{ width: 120 }} >
                                        <Select.Option value={''}>Lựa chọn</Select.Option>
                                        {
                                            village?.data && village?.data.length > 0 ? village?.data.map((item, key) => {
                                                return (
                                                    <Select.Option key={key} value={item?.id}>{item.name}</Select.Option>
                                                )
                                            })
                                                : null
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <div>Số thửa</div>
                                <Form.Item label="" name="number_land">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <div>Số Trang</div>
                                <Form.Item label="" name="page_sheet">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <div>Tên</div>
                                <Form.Item label="" name="name_search">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <div>CMND</div>
                                <Form.Item label="" name="cmnd_search">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <div>Thông tin thêm - và nội dung</div>
                                <Form.Item label="" name="note_search">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: 12 }}>
                            <Col>
                                <Button type="danger" htmlType="submit">Tìm kiếm</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div>
        </>
    )
}
