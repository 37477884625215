import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { urlBaseGetImage } from "../../Helper/helpFunction";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ExcelJS from 'exceljs';
import './Export.scss'

export default function Export() {
  const params = useParams();
  const id = params?.id;
  const [data, setData] = useState([])
  const [name, setName] = useState([])

  useEffect(() => {
    fetch();
  }, [])

  const fetch = async () => {
    const res = await axios.get(`${urlBaseGetImage(`/api/village/export2data/${id}`)}`)
    if (res?.status === 200) {
      setData(res?.data?.data?.parcel_of_land_info)
      setName(res?.data?.data?.name)
    }
  }

  const handlePrint = () => {
    var content = document.getElementById("table-to-xls");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  const exportToExcel = (data) => {
    const fileName = 'simple.xlsx';
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('My Sheet');

    ws.getCell('A1').value = 'John Doe';
    ws.getCell('B1').value = 'gardener';
    ws.getCell('C1').value = new Date().toLocaleString();

    const r3 = ws.getRow(3);
    r3.values = [1, 2, 3, 4, 5, 6];

    wb.xlsx
      .writeFile(fileName)
      .then(() => {
        console.log('file created');
      })
      .catch(err => {
        console.log(err.message);
      });
  }

  const page = 0 || (params?.page ? parseInt(params?.page) : 0);
  const totalPage = parseInt(data.length / 80) + 1;
  const urlPath = window.location.origin;
  const fontSize = '12px'
  const border1 = '1px dotted #5b5b5b'
  const border2 = '1px solid #5b5b5b'
  return (
    <>
      <button onClick={() => exportToExcel([
        { name: 111, test: 222, ba: 444 }
      ])}>export</button>
      <button onClick={() => handlePrint()}>Print</button>
      <div>
        {
          (totalPage && totalPage > 1) && Array.apply(0, Array(totalPage)).map((item, key) => {
            return (
              <button onClick={() => window.open(`${urlPath}/admin/village/export2/${id}/${key}`)}>Page {key + 1}</button>
            )
          })
        }
      </div>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={`${name}`}
        sheet="tablexls"
        buttonText="Download Excel" />
      <table id="table-to-xls" style={{ margin: 0, padding: 0 }}>
        {
          data && data.length > 0 ? data?.map((item, key) => {
            if (key > 1) {
              return null
            }
            let countThuadat = 0;
            let countThaydoi = 0;
            // if (item?.fullname1 !== 'nguyễn văn tuyên') {
            //   return null
            // }
            // console.log(item);
            if ((page * 80 <= key && (page + 1) * 80 > key)) {
              return (
                <tbody key={key}>
                  <tr>
                    <td colSpan="5" style={{ fontSize: fontSize, textAlign: 'left' }}>
                      (Tiếp theo trang số: ...............)
                    </td>
                    <td colSpan="5" style={{ fontSize: fontSize, textAlign: 'right' }}>
                      Trang {item?.page_sheet}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="10"
                      style={{ fontSize: '16px', textAlign: 'center', border: border2, fontWeight: 'bold' }}>
                      I-NGƯỜI SỬ DỤNG ĐẤT
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="10" style={{ fontSize: fontSize, border: border2 }}>Chủ hộ 1:
                      <strong>{item?.fullname1}</strong>, sinh năm:
                      <strong>{item?.age1}</strong>, CMND/CCCD:
                      <strong>{item?.cmnd1}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="10" style={{ fontSize: fontSize, border: border2 }}>Chủ hộ 2:
                      <strong>{item?.fullname2}</strong>, sinh năm:
                      <strong>{item?.age1}</strong>,
                      CMND/CCCD: <strong>{item?.cmnd1}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="10" style={{ fontSize: fontSize, border: border2 }}>Địa
                      chỉ: {item?.address}</td>
                  </tr>
                  <tr>
                    <td colSpan="10"
                      style={{ fontSize: '16px', textAlign: 'center', border: border2, fontWeight: 'bold' }}>
                      II-THỬA ĐẤT
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan="2" style={{
                      borderBottom: border2, borderLeft: border2,
                      borderRight: border2, textAlign: 'center'
                    }}>
                      Ngày tháng <br /> năm vào
                    </td>
                    <td rowSpan="2" style={{ border: border2, textAlign: 'center', width: 75 }}>Số thứ tự <br /> thửa đất</td>
                    <td rowSpan="2" style={{ border: border2, textAlign: 'center', width: 75 }}>Số thứ tự<br /> tờ bản đồ</td>
                    <td rowSpan="1" style={{ border: border2, textAlign: 'center', width: 95 }} colSpan="2">Diện tích <br /> sử dụng (m2)</td>
                    <td rowSpan="2" style={{ border: border2, textAlign: 'center', width: 75 }}>Mục đích <br /> sử dụng</td>
                    <td rowSpan="2" style={{ border: border2, textAlign: 'center', width: 95 }}>Thời hạn <br /> sử dụng</td>
                    <td rowSpan="2" style={{ border: border2, textAlign: 'center', width: 95 }}>Nguồn gốc <br /> sử dụng</td>
                    <td rowSpan="2" style={{ border: border2, textAlign: 'center', width: 95 }}>Số phát <br /> hành GCN QSDĐ</td>
                    <td rowSpan="2" style={{ border: border2, textAlign: 'center', width: 95 }}>Số vào sổ <br /> cấp GCN QSDĐ</td>
                  </tr>
                  <tr>
                    <td style={{ border: border2, textAlign: 'center', width: 75 }}>riêng</td>
                    <td style={{ border: border2, textAlign: 'center', width: 75 }}>chung</td>
                  </tr>
                  {
                    item?.thuadat && item?.thuadat.length > 0 ? item?.thuadat.map((item2, key2) => {
                      countThuadat++;
                      return (
                        <tr key={`thua-${key2}`}>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {`${item2?.ngaythangnamvao ? `.${item2?.ngaythangnamvao}.` : ''}`}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item2?.sothututhuadat}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item2?.sothutubando}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item2?.rieng}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item2?.chung}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item2?.mucdichsudung}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {`${item2?.thoihansudung ? `.${item2?.thoihansudung}.` : '-'} `}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item2?.nguongocsudung}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item2?.sophathanhgcn}
                          </td>
                          <td style={{
                            borderBottom: border1, borderLeft: border2, borderRight: border2,
                            fontSize: fontSize, textAlign: 'center', height: 25, textDecoration: item2?.checklinethua ? 'line-through' : ''
                          }}>
                            {item?.sovaosocapgcnqsdd}
                          </td>
                        </tr >
                      )
                    }) : null
                  }
                  {countThuadat && countThuadat > 0 && countThuadat <= 22 ? Array.apply(0, Array(22 - countThuadat)).map(function (x, i) {
                    return <tr key2={`stt-${i}`}>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                    </tr>
                  }) : null}
                  <tr>
                    <td colSpan="10"
                      style={{ fontSize: '16px', textAlign: 'center', border: border2, fontWeight: 'bold' }}>
                      III
                      - NHỮNG THAY ĐỔI TRONG QUÁ TRÌNH SỬ DỤNG ĐẤT VÀ GHI CHÚ
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: border2, textAlign: 'center' }}>số thứ tự <br /> thửa đất</td>
                    <td style={{ border: border2, textAlign: 'center' }}>ngày tháng <br /> năm</td>
                    <td style={{ border: border2, textAlign: 'center' }} colSpan="8">
                      NỘI DUNG GHI CHÚ HOẶC BIẾN ĐỘNG VÀ CĂN CỨ PHÁP LÝ
                    </td>
                  </tr>
                  {
                    item?.thaydoi && item?.thaydoi.length > 0 ? item?.thaydoi.map((item2, key2) => {
                      const text = item2?.noidung
                      const totalMapLength = text ? parseInt(text.length / 100) : ''
                      if (text && text.length > 100 && totalMapLength && totalMapLength > 0) {
                        Array.apply(0, Array(totalMapLength)).map((item5, key) => {
                          countThaydoi++;
                          return (
                            <tr key={`thaydoi-${key}-${key2}`}>
                              <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, textAlign: 'center', height: 25, textDecoration: item2?.checkline ? 'line-through' : '' }}>
                                {item2?.sothututhuadat}
                              </td>
                              <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, textAlign: 'center', height: 25, textDecoration: item2?.checkline ? 'line-through' : '' }}>
                                {`${item2?.ngaythangnam ? `.${item2?.ngaythangnam}.` : ''}`}
                              </td>
                              <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, textDecoration: item2?.checkline ? 'line-through' : '' }} colSpan="8">
                                {text.slice(100 * key, 100 * (key + 1))}
                              </td>
                            </tr>
                          )
                        })
                      } else {
                        countThaydoi++;
                        return (
                          <tr key={`thaydoi-${key}-${key2}`}>
                            <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, textAlign: 'center', height: 25, textDecoration: item2?.checkline ? 'line-through' : '' }}>
                              {item2?.sothututhuadat}
                            </td>
                            <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, textAlign: 'center', height: 25, textDecoration: item2?.checkline ? 'line-through' : '' }}>
                              {`${item2?.ngaythangnam ? `.${item2?.ngaythangnam}.` : ''}`}
                            </td>
                            <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, textDecoration: item2?.checkline ? 'line-through' : '' }} colSpan="8">
                              {item2?.noidung}
                            </td>
                          </tr>
                        )
                      }

                    }) : null
                  }
                  {countThaydoi && countThaydoi > 0 && countThaydoi <= 22 ? Array.apply(0, Array(22 - countThaydoi)).map(function (x, i) {
                    return <tr key2={`stt2-${i}`}>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                      <td style={{ borderBottom: border1, borderLeft: border2, borderRight: border2, height: 25, }} colSpan="8"></td>
                    </tr>
                  }) : null}
                  <tr>
                    <td style={{ borderBottom: border2, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                    <td style={{ borderBottom: border2, borderLeft: border2, borderRight: border2, height: 25, }}></td>
                    <td style={{ borderBottom: border2, borderLeft: border2, borderRight: border2, height: 25, }} colSpan="8"></td>
                  </tr>
                  <tr>
                    <td style={{ height: 25, textAlign: 'right' }} colSpan="10">Chuyển tiếp trang số: ..................</td>
                  </tr>
                  <tr>
                    <td></td>
                  </tr>
                </tbody >
              )
            } else {
              return null;
            }
          }) : null
        }
      </table>
      <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
    </>
  )
}