import {LOGIN_ADMIN, LOGOUT_ADMIN} from "../../constants/constants";

let user = null
if(localStorage.getItem('user_admin')) {
    user = {user: JSON.parse(localStorage.getItem('user_admin'))}
}

const currentUser = (state = user, action) => {
  switch (action.type) {
    case LOGIN_ADMIN:
      return {
        user: action.payload,
      };
    case LOGOUT_ADMIN:
      return null;
    default:
        return state;
  }
};

export default currentUser;
