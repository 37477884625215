import { axiosGET, axiosPOST } from '../../Helper/TypeAxios'
import { urlParseParams } from "../../Helper/helpFunction";

const routerRoot = 'parcellandinformation';

export default class ParcellandInformation {

  ParcelPrint(data) {
    const stringUrl = urlParseParams(data);
    return axiosGET(`/${routerRoot}/listPrint?${stringUrl}`, {})
  }
}
