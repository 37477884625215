import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useHistory } from "react-router-dom";
import API from '../../service/API'

export default function PermissionCreate() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [listRouter, setListRouter] = useState(null);

    const fetchRouter = async () => {
        const res = await API.Permission.getAll();
        let listOption = [];

        if (res?.status === 200) {
            res?.data?.map((item) => {
                listOption.push(<Select.Option key={item?.router} value={item?.router}>{item?.name}</Select.Option>)
            })
            setListRouter(listOption)
        }
    };

    useEffect(() => {
        fetchRouter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFinish = async (values) => {
        setLoading(true);
        const data = {
            name: values?.name ? values.name : null,
        };

        setTimeout(async () => {
            const res = await API.Permission.createData(data);
            if (res?.status === 200) {
                const group_id = res?.data
                if (group_id) {
                    await API.Permission.createGroup({
                        group_id: group_id,
                        list_permission: values?.list_permission
                    });
                }
                // history.push(RouterLink.EtPermissionGroup.List);
            }
            setLoading(false);
        }, 1500)
    };

    return (
        <>
            <Row justify="center">
                <Col span={6}>
                    <Form form={form} name="basic" onFinish={onFinish}>
                        <Form.Item
                            label="Tên nhóm quyền"
                            name="name"
                            rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Quyền"
                            name="list_permission"
                            rules={[{ required: true, message: 'Trường yêu cầu nhập!' }]}
                        >
                            <Select mode="multiple" className="kllzlteejc"
                                size={10}>
                                {listRouter}
                            </Select>
                        </Form.Item>
                        <Row justify={'center'}>
                            <div className="box-btn-submit-form">
                                <Button type={"primary"} htmlType="submit" danger>Tạo mới</Button>
                            </div>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button type={"primary"} onClick={() =>
                        window.open(`${process?.env?.REACT_APP_URL_BASE || document.getElementById('REACT_APP_URL_BASE')?.innerText}/api/v1/fetchRouter`)}
                        danger>Làm mới</Button>
                </Col>
            </Row>
        </>
    )
}
